export const title = '跃龙保安管理系统后台'
export const baseurl = 'https://api.mgylba.cn'
//export const baseurl = 'http://127.0.0.1:88'
//export const baseurl = 'https://ehr.pipicloud.com'


export default{
    baseurl,
    title
}
