import { setToken, removeToken} from '@/utils/auth'
import { loginReq} from '@/api/index'

const mutations = {

}

const actions = {
    //user login
    // eslint-disable-next-line no-unused-vars
    login({commit},data){
        return new Promise((resolve,reject)=>{
            loginReq(data).then((res)=>{
                if(res.code ===200){
                    setToken(res.token)
                    resolve()
                }else{
                    reject(res)
                }
            }).catch((err)=>{
                reject(err)
            })
        })

    },
    // reset or del token
    resetToken(){
        return new Promise((resolve)=>{
            removeToken()
            resolve()
        })
    }
}

export default {
    namespaced: true,
    mutations,
    actions
}