import request from '@/utils/request'


//获取中队列表
export function getTeamListReq() {
    return request({
        url: '/admin/common/team/list/',
        method: 'get'
    })
}

//获取考核子项列表
export function getASubListReq() {
    return request({
        url: '/admin/common/assess/sub/list/',
        method: 'get'
    })
}

// 获取人员属性
export function getUserStruct() {
    return request({
        url: '/admin/common/list',
        method: 'get'
    })

}