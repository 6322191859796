/* eslint-disable no-unused-vars */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn'
//import locale from 'element-plus/lib/locale/lang/zh-cn' //中文

const app = createApp(App)

//global mixin
import elementMixin from '@/mixins/elementMixin'
app.mixin(elementMixin)

//ico
import SvgIcon from '@/icons/SvgIcon';
app.component('svg-icon', SvgIcon)
import './icons'

import './permission'
app.use(store).use(ElementPlus,{locale}).use(router).mount('#app')
