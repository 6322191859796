import { createRouter, createWebHashHistory } from 'vue-router'
const Layout = () => import('@/layout')

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/Login.vue'),
    hidden: true,
    meta: { title: '登录页面' }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/index.vue'),
      meta: { title: '首页信息', ico: 'House' }
    }]
  },
  {
    path: '/services',
    component: Layout,
    redirect: '/service/user',
    meta: { title: '人事管理', ico: 'User' },
    children: [
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/services/User/User.vue'),
        meta: { title: '职工管理', ico: 'UserFilled' }
      },
      {
        path: 'jobchange',
        name: 'jobchange',
        component: () => import('@/views/services/JobHistory/jobHistory.vue'),
        meta: { title: '人事变动', ico: 'User' }
      },
       {
         path: 'reserve',
        name: 'planB',
         component: () => import('@/views/services/User/Reserve/Reserve.vue'),
        meta: { title: '人员储备(Beta)', ico: 'User' } 
      },
      {
        path: 'team',
        name: 'team',
        component: () => import('@/views/services/Team/Team.vue'),
        meta: { title: '中队管理', ico: 'List' }
      },
      {
        path: 'serviceunit',
        name: 'serviceunit',
        component: () => import('@/views/services/Unit/Service.vue'),
        meta: { title: '服务单位管理', ico: 'OfficeBuilding' }
      },
    /*   {
        path: 'jobchange',
        name: 'jobchange',
        component: () => import('@/views/services/JobHistory/jobHistory.vue'),
        meta: { title: '留言管理(TBD)', ico: 'User' }
      }, */
    ]
  },
  {
    path: '/assess',
    component: Layout,
    redirect: '/assess/list',
    meta: { title: '评分管理', ico: 'Star' },

    children: [
      {
        path: 'list',
        name: 'list',
        component: () => import('@/views/assess/List/List.vue'),
        meta: { title: '评分任务列表', ico: 'List' }
      },
      {
        path: 'sub',
        name: 'sub',
        component: () => import('@/views/assess/sub/List.vue'),
        meta: { title: '评分项目设置', ico: 'StarFilled' }
      },
        {
          path: 'merit',
          name: 'merit',
          component: () => import('@/views/assess/merit/merit.vue'),
          meta: { title: '额外加分项', ico: 'Check' }
       }
    ]

  },
/*   {
    path: '/Warehouse',
    component:Layout,
    redirect:'/warehouse/list',
    meta: { title: '仓库管理(TBD)', ico:'TakeawayBox'},
     children:[
      {
         path: 'list',
        // name: 'warehouse/list',
        component: () => import('@/views/warehouse/List/list.vue'),
        meta: { title: '物品类型' }
      },
      {
        path: 'type',
        name: 'type',
        component: () => import('@/views/assess/List/List.vue'),
        meta: { title: '物品出入库' }
      },
     

    ]
  } */
  /* {
    path: '/typemanager',
    component: Layout,
    redirect: '/type/service',
    meta: { title: '类型管理', ico: 'HelpFilled' },

    children: [
      {
        path: 'service',
        name: 'service',
        component: () => import('@/views/services/User/User.vue'),
        meta: { title: '服务单位类型', ico: 'Flag' }
      },
      {
        path: 'safe',
        name: 'safe',
        component: () => import('@/views/services/User/User.vue'),
        meta: { title: '安保服务类型', ico: 'Flag' }
      },
      {
        path: 'recruitment',
        name: 'recruitment',
        component: () => import('@/views/services/User/User.vue'),
        meta: { title: '招聘方式', ico: 'Flag' }
      }
    ]

  } */

]

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
})

export default router
