import {
  createStore
} from 'vuex'
//简单的方式
//webpack中
const modulesFiles = require.context('./modules',false,/\.js$/)
let modules = {}

modulesFiles.keys().forEach((key) => {
  modules[key.replace(/\.\/|\.js/g, '')] = modulesFiles(key).default
})
/* Object.keys(modules).forEach(key => {
  modules[key]['namespaced'] = true
}) */



//vite 中
//const modulesFiles = import.meta.glob('./modules/*.js')
//let modules = {}

//for (const path in modulesFiles) {
 // const moduleName = path.replace(/(.*\/)*([^.]+).*/gi, '$2')
 // modules[moduleName] = modulesFiles[path].default
//}
//复杂的方式
// const modulesFiles = import.meta.globEager('./modules/*.js')
// console.log(Object.keys(modulesFiles));
// const modules = Object.keys(modulesFiles).reduce((modules, modulePath) => {
//   // const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
//   const moduleName = modulePath.replace(/(.*\/)*([^.]+).*/gi, '$2')
//   const value = modulesFiles[modulePath]
//   modules[moduleName] = value.default
//   return modules
// }, {})
// console.log(modules);
export default createStore({
  modules
})