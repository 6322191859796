import request from '@/utils/request'

export function getServerInfoReq(){
    return request({
        url:'/admin/server',
        method:'get'
    })
}

export function getAllPeopleReq(){
    return request({
        'url':'/admin/all_num',
        'method':'get'
    })
}

export function loginReq(data){
    return request({
        'url': '/admin/login',
        data,
        'method': 'post'
    })
}