const mixin = {
    data() {
        //身份证校验
        const idCardValidity = (rule, code, callback) => {
            let city = { 11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江 ", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北 ", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏 ", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外 " };
            let tip = ""
            let pass = true
            if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) {
                tip = "身份证号格式错误"
                pass = false;
            } else if (!city[code.substr(0, 2)]) {
                // tip = "地址编码错误"
                tip = "身份证输入错误"
                pass = false
            } else {
                // 18位身份证需要验证最后一位校验位
                if (code.length === 18) {
                    code = code.split('')
                    // ∑(ai×Wi)(mod 11)
                    // 加权因子
                    let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
                    // 校验位
                    let parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
                    let sum = 0
                    let ai = 0
                    let wi = 0
                    for (let i = 0; i < 17; i++) {
                        ai = code[i]
                        wi = factor[i]
                        sum += ai * wi
                    }
                    if (parity[sum % 11] != code[17]) {
                        // tip = "校验位错误"
                        tip = "身份证输入错误"
                        pass = false
                    }
                }
            }
            if (!pass) {
                callback(new Error(tip))
            } else {
                callback()
            }
        }

        // 设置手机号的验证规则
        const phoneRules = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入联系方式'))
            } else {
                //const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/
                const reg = /^1[3|4|5|6|7|8|9]\d{9}$/
                if (reg.test(value)) {
                    callback()
                } else {
                    return callback(new Error('请输入正确的电话'))
                }
            }
        }
        // 大于0的整数
        let upZeroIntCanNull = (rule, value, callback) => {
            if (!value) {
                callback()
            } else {
                if (!/^\+?[1-9]\d*$/.test(value)) {
                    callback(new Error('大于0的整数'))
                } else {
                    callback()
                }
            }
        }
        // 整数
        let ZeroIntCanNull = (rule, value, callback) => {
            if (!value) {
                callback()
            } else {
                if (!/^\+?[0-9]\d*$/.test(value)) {
                    callback(new Error('必须是整数'))
                } else {
                    callback()
                }
            }
        }

        return {
            formRulesMixin:{
                credentialsCode: [{ required: true, message: '请输入证件号', trigger: 'blur' },
                {
                    pattern: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
                    message: '请输入正确的证件号'
                },
                { validator: idCardValidity, trigger: 'blur' }],
                isNotNull: [{ required: true, message: '该信息不能为空', trigger: 'blur' }],
                isNotNullSecond: [{ required: true, message: '不能为空', trigger: 'blur' }],
                upZeroIntCanNull: [{ required: true, message: '该信息不能为空'},{ validator: upZeroIntCanNull, trigger: 'blur' }],
                ZeroIntCanNull: [{ required: true, message: '该信息不能为空' }, { validator: ZeroIntCanNull, trigger: 'blur' }],
                mobile: [
                    { required: true, validator: phoneRules, trigger: 'blur' }
                ],
            }
        }
    }
}

export default mixin