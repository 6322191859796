import { getTeamListReq, getASubListReq } from '@/api/common'

const state = {
    teamList:{},
    assesssub:{},
}
const mutations = {
    SetTeamList(state, payload){
        state.teamList = payload
    },
    SetASubList(state,payload){
        state.assesssub = payload
    }
    
}

const actions = {
    getTeamList({commit})
    {
        return new Promise((resolve)=>{
            getTeamListReq().then(res=>{
                commit('SetTeamList',res.data)
                resolve()
            })
        })

    },
    getASubList({commit})
    {
        return new Promise((resolve)=>{
            getASubListReq().then(res=>{
                commit('SetASubList',res.data)
                resolve()
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}