import defaultSettings from '@/settings'
const title = defaultSettings.title || '管理后台'
const TokenKey = 'Admin-Token'
export const getToken = ()=>{
    return localStorage.getItem(TokenKey)
}
export const setToken = (token)=>{
    return localStorage.setItem(TokenKey, token)
}
export const removeToken = () => {
    return localStorage.removeItem(TokenKey)
}

export const GetPageTitle = (pageTitle)=>{
    if (pageTitle) {
        return `${pageTitle} - ${title}`
    }
    return `${title}`
}
